
// import Shuffle from 'shufflejs'
// import objectFitPolyfill from 'objectFitPolyfill'
// import vidim from 'vidim'
import EmblaCarousel from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'
import {
    addPrevNextBtnsClickHandlers,
    addTogglePrevNextBtnsActive,
    addDotBtnsAndClickHandlers,
  } from './arrows-dots-buttons'
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()
import Rellax from 'rellax'
import baguetteBox from 'baguettebox.js';

// Helper
const isMobile = window.matchMedia('(max-width: 599px)').matches

// Slider hero quotes
function HeroQuoteSlider() {
    const heroNode = document.getElementById('hero__quotes')
    const heroActiveClassName = 'quotes__item--active'
    const quoteActiveClassName = 'indicator__item--active'
    if (heroNode) {
        heroNode.addEventListener('click', event => {
            if (timer) {
                clearInterval(timer)
            }
            setNextItem()
        })
        let activeIx = -1
        const quoteItemNodes = heroNode.getElementsByClassName('quotes__item')
        const quoteItemPagination = heroNode.getElementsByClassName(
            'indicator__item'
        )
        const maxItemLength = quoteItemNodes.length
        const setNextItem = () => {
            let nextItem = activeIx + 1
            if (nextItem >= maxItemLength) {
                nextItem = 0
            }
            activeIx = nextItem
            const itemEl = quoteItemNodes[activeIx]
            if (itemEl) {
                Array.from(quoteItemNodes).forEach(el =>
                    el.classList.remove(heroActiveClassName)
                )
                itemEl.classList.add(heroActiveClassName)
            }
            const pagEl = quoteItemPagination[activeIx]
            if (pagEl) {
                Array.from(quoteItemPagination).forEach(el =>
                    el.classList.remove(quoteActiveClassName)
                )
                pagEl.classList.add(quoteActiveClassName)
            }
        }
        setNextItem()
        let timer = setInterval(setNextItem, 7000)
    }
}
function HeroVideo() {
    const heroVideo = document.getElementById('image__video')
    const heroEl = document.getElementById('home__hero')
    if (!heroVideo || !heroEl) {
        return
    }
    const youtubeSrc = heroVideo.dataset.video


    const poster = heroVideo.dataset.poster
    if (youtubeSrc && heroVideo && !isMobile) {
        const el = new vidim('#image__video', {
            src: youtubeSrc,
            loop: false,
            quality: 'highres',
            showPosterOnEnd: true,
            poster,
            type: 'YouTube',
        })

        el.addEventListener('end', () => {
            heroEl.classList.add('hero--ended')
            const heroPlay = document.getElementById('hero__play')
            heroPlay.addEventListener('click', () => {
                heroEl.classList.remove('hero--ended')
                el.play();
            })

        })
        el.addEventListener('start', () => {
            heroEl.classList.remove('hero--ended')
        })
    }
    const heroPlay = document.getElementById('hero__play')
    if (heroPlay && isMobile) {
        const iframe = document.getElementById('youtube');
        const close = document.getElementById('close-video-modal');
        const body = document.getElementsByTagName('body')[0]
        const overlay = document.getElementsByClassName('video-modal')[0]

        heroPlay.addEventListener('click', () => {
            body.classList.toggle('show-video-modal');
            body.classList.toggle('noscroll');
            const src = `//www.youtube.com/embed/${heroPlay.dataset.id}?autoplay=1&rel=0`;
            iframe.src = src;

            overlay.addEventListener('click', () => {
                body.classList.remove('show-video-modal');
                body.classList.remove('noscroll');
                iframe.src = '';
            });
        });

        close.addEventListener('click', () => {
            body.classList.remove('show-video-modal');
            body.classList.remove('noscroll');
            iframe.src = '';
        });
    }
}

function HomeShows() {
    const showsContainer = document.getElementById('home__shows')
    const activeClassFilter = 'filter__item--active'
    const activeClassElement = 'items__image--active'
    let filterIds = []
    if (showsContainer) {
        const startValue = showsContainer.dataset.start || 'filter--most-booked';

        // Mobile Filter select
        const mobileFilterSelect = showsContainer.getElementsByTagName(
            'select'
        )[0]

        // Get Filter Ids
        const filtersContainer = showsContainer.getElementsByClassName(
            'content__filter'
        )

        if (filtersContainer && filtersContainer[0]) {
            const filters = filtersContainer[0].getElementsByTagName('a')
            filterIds = [...filters].map(f => f.id)
        }

        // get filter Items

        const onChangeFilter = e => {
            e.preventDefault()
            changeFilter(e.target.value)
        }

        const onClickFilter = e => {
            e.preventDefault()
            changeFilter(e.target.id)
        }

        const changeFilter = nextFilterId => {
            const filterItems = [
                ...showsContainer.getElementsByClassName('items__image'),
            ]

            const nextId = `${nextFilterId}`.replace('filter--', '')

            // Remove Active Ids from filters
            filterIds.forEach(id => {
                const el = document.getElementById(id)
                el.parentNode.classList.remove(activeClassFilter)
                if (el.id === nextFilterId) {
                    el.parentNode.classList.add(activeClassFilter)
                }
            })
            const sorting = []
            filterItems.forEach(el => {
                let elementFilters = []
                let sort = 1000
                try {
                    elementFilters = JSON.parse(el.dataset.filter)
                    sort = parseFloat(el.dataset.order || 10000, 10)
                } catch (err) {}

                el.classList.remove(activeClassElement)
                if (elementFilters.indexOf(nextId) !== -1 || nextId === 'all') {
                    el.classList.add(activeClassElement)
                }
                sorting.push(sort)
            })
            const elements = document.createDocumentFragment();
            const sels = filterItems.map((el, ix) => {
                return {el, sort: sorting[ix]}
            }).sort((a,b) => a.sort - b.sort).forEach(el => {

                elements.append(el.el.cloneNode(true))
            })

            const container = showsContainer.getElementsByClassName('content__items')[0]
            container.innerHTML = null;
            container.appendChild(elements);

            // Select mobile select
            // mobileFilterSelect.selectedIndex = filterIds.indexOf(nextFilterId)
        }

        // Attach Click Handler
        filterIds.forEach(id => {
            const el = document.getElementById(id)
            el.addEventListener('click', onClickFilter)
        })

        // Attach onChange filter for select
        mobileFilterSelect.addEventListener('change', onChangeFilter)

        // Start Filter of best sellers
        changeFilter(startValue)
    }
}

// Gallery Helper Functions
const autoPlay = (embla, interval) => {
    const state = { timer: 0 }

    const play = () => {
        stop()
        requestAnimationFrame(
            () => (state.timer = window.setTimeout(next, interval))
        )
    }

    const stop = () => {
        window.clearTimeout(state.timer)
        state.timer = 0
    }

    const next = () => {
        if (embla.canScrollNext()) {
            embla.scrollNext()
        } else {
            embla.scrollTo(0)
        }
        play()
    }

    return { play, stop }
}

function EmblaSlider() {
    const options = { loop: true }
    const emblaNode = document.querySelector('.embla')
    const viewportNode = emblaNode.querySelector('.embla__viewport')
    const prevBtn = emblaNode.querySelector('.embla__button--prev')
    const nextBtn = emblaNode.querySelector('.embla__button--next')
    const dotsNode = document.querySelector('.embla__dots')
    const autoplayOptions = {
        delay: 8000,
        rootNode: (emblaRoot) => emblaRoot.parentElement,
    }
    const emblaApi = EmblaCarousel(viewportNode, options, [
        Autoplay(autoplayOptions),
      ])

    const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
        emblaApi,
        prevBtn,
        nextBtn,
        )
        const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
        emblaApi,
        prevBtn,
        nextBtn,
    )
    const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
        emblaApi,
        dotsNode,
        )
        emblaApi
        .on('destroy', removePrevNextBtnsClickHandlers)
        .on('destroy', removeTogglePrevNextBtnsActive)
        .on('destroy', removeDotBtnsAndClickHandlers)

}


function HomeGallery() {
    baguetteBox.run('.gallery');
    // new Tobi({selector: '.gallery__item--link'})
}

function Navigation() {
    const menu = document.getElementById('hamburger')
    const body = document.getElementsByTagName('body')[0]
    const main = document.getElementsByTagName('main')[0]
    const header = document.getElementsByTagName('header')[0]
    const navItems = document.getElementsByClassName('navigationmobile__item')

    let isActive = false

    if (menu) {
        const toggle = () => {
            isActive = !isActive
            body.classList.toggle('nav--open')
            menu.classList.toggle('is-active')
        }

        const onClose = e => {
            if (main.contains(e.target)) {
                toggle()
                body.removeEventListener('click', onClose)
            }
        }

        menu.addEventListener('click', event => {
            event.preventDefault()
            toggle()
            setTimeout(() => {
                if (isActive) {
                    body.addEventListener('click', onClose)
                } else {
                    body.removeEventListener('click', onClose)
                }
            }, 20)
        })

        ;[...navItems].forEach(el => {
            el.addEventListener('click', () => {
                toggle()
            })
        })
        let last_known_scroll_position = 0
        let ticking = false

        const animateHeader = position => {
            if (position > 50) {
                header.classList.add('header--active')
            } else {
                header.classList.remove('header--active')
            }
        }
        const headerClass = [...header.classList]
        if (headerClass.indexOf('header--active') === -1) {
            window.addEventListener('scroll', function(e) {
                last_known_scroll_position = window.scrollY

                if (!ticking) {
                    window.requestAnimationFrame(function() {
                        animateHeader(last_known_scroll_position)
                        ticking = false
                    })

                    ticking = true
                }
            })
        }
    }
}

// Show All Links
function showAllLinks() {
    const showAllLinks = document.getElementsByClassName('showall__link')
    ;[...showAllLinks].forEach(link => {
        link.addEventListener('click', event => {
            event.preventDefault()
            const hiddenElements = event.target.parentNode.parentNode.getElementsByClassName(
                event.target.dataset.class
            )
            let hasMore = false;
            ;[...hiddenElements].forEach((element, ix) => {
                if (ix < 6) {
                    element.classList.remove(event.target.dataset.class)
                }

                if (ix >= 6) {
                    hasMore = true
                }
            })
            if (!hasMore) {
                event.target.style = 'display:none;'
            }
        })
    })
}

function Instagramm() {
    const iel = document.getElementById('cinstagram');
    if (iel) {
        fetch("https://looop.mllrsohn.workers.dev/")
        .then(res => res.json())
        .then(res => {
            res.forEach((item, ix) => {
                if (ix > 5) {
                    return;
                }
                const nide = document.createElement("a");
                nide.style = `background-image: url("${item.src}");`;
                nide.href = item.url;
                nide.classList.add('instagram__image')
                iel.appendChild(nide);
            })
        })
    }
}

function ContactForm() {
    const el = document.getElementById('contact_form');
    if (el) {
        el.addEventListener('submit', function (e) {
            document.getElementById('contact_error').style = "display:none;"
            e.preventDefault();
            const formData = new FormData(e.target);
            const body = JSON.stringify([...formData].reduce((prev, cur) => { prev[cur[0]] = cur[1]; return prev;}, {}))
            fetch('https://looop.mllrsohn.workers.dev', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body,
            }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    document.getElementById('form_submit').style = "display:none;"
                    document.getElementById('contact_success').style = "display:block;"
                } else {
                    document.getElementById('contact_success').style = "display:none;"
                    document.getElementById('contact_error').style = "display:block;"
                }
            })
            return false;
        }, false)
    }

}

// Functions
Navigation()
HeroQuoteSlider()
HomeShows()
showAllLinks()
Instagramm()
HomeGallery()
ContactForm()
HeroVideo()
EmblaSlider()



